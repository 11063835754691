<template>
  <div>

    <div class="iframeclass">
      <iframe class="iframe" src="https://www.appeq.ai/welcome" scrolling="no" />
    </div>
  </div>
</template>
<script>
import Server from "@/components/API/Server.js";
import LocalUtils from "../components/Utils/LocalUtils";
import MainOnboardingVue from "./Onboarding/MainOnboarding.vue";
export default {
  name: "Dashboard",
  components: {
    MainOnboardingVue
  },
  computed: {},
  methods: {
    loginwithgoogle() {
      // Server.login();
    },
  },
  mounted() {
    
  },
};
</script>
<style>
.iframe {
  width: 100%;
  height: 1000px;
  border: none;
  position: absolute;
  top: -140px;
  left: -20px;
  bottom: -20px;
}

.iframeclass {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}
</style>
